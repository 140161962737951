import { Record } from "../.fable/fable-library.3.1.15/Types.js";
import { list_type, record_type, anonRecord_type, option_type, string_type } from "../.fable/fable-library.3.1.15/Reflection.js";

export class Alert extends Record {
    constructor(Labels, Annotations) {
        super();
        this.Labels = Labels;
        this.Annotations = Annotations;
    }
}

export function Alert$reflection() {
    return record_type("Shared.Alert", [], Alert, () => [["Labels", anonRecord_type(["Alertname", string_type], ["Instance", option_type(string_type)], ["Job", option_type(string_type)], ["Severity", string_type])], ["Annotations", anonRecord_type(["Message", string_type])]]);
}

export class AlertList extends Record {
    constructor(Alerts) {
        super();
        this.Alerts = Alerts;
    }
}

export function AlertList$reflection() {
    return record_type("Shared.AlertList", [], AlertList, () => [["Alerts", list_type(Alert$reflection())]]);
}

export class Alerts extends Record {
    constructor(Data) {
        super();
        this.Data = Data;
    }
}

export function Alerts$reflection() {
    return record_type("Shared.Alerts", [], Alerts, () => [["Data", AlertList$reflection()]]);
}

export class Dashboard extends Record {
    constructor(Name, URL$) {
        super();
        this.Name = Name;
        this.URL = URL$;
    }
}

export function Dashboard$reflection() {
    return record_type("Shared.Dashboard", [], Dashboard, () => [["Name", string_type], ["URL", string_type]]);
}

export class ServerInfo extends Record {
    constructor(Name, AlertsURL, Dashboards) {
        super();
        this.Name = Name;
        this.AlertsURL = AlertsURL;
        this.Dashboards = Dashboards;
    }
}

export function ServerInfo$reflection() {
    return record_type("Shared.ServerInfo", [], ServerInfo, () => [["Name", string_type], ["AlertsURL", option_type(string_type)], ["Dashboards", list_type(Dashboard$reflection())]]);
}

