import { reverse, empty, cons, fold } from "../fable-library.3.1.15/List.js";
import { uncurry } from "../fable-library.3.1.15/Util.js";
import { toString } from "../fable-library.3.1.15/Types.js";
import { join } from "../fable-library.3.1.15/String.js";

export function Helpers_extractClasses(xs) {
    return fold(uncurry(2, (tupledArg) => {
        const classes = tupledArg[0];
        const props = tupledArg[1];
        return (x) => {
            const matchValue = x;
            return (matchValue[0] === "className") ? [cons(toString(matchValue[1]), classes), props] : [classes, cons(x, props)];
        };
    }), [empty(), empty()], reverse(xs));
}

export function Helpers_combineClasses(cn, xs) {
    const tupledArg = Helpers_extractClasses(xs);
    return cons(["className", join(" ", cons(cn, tupledArg[0]))], tupledArg[1]);
}

