import { compare, physicalHash, equals, structuralHash } from "./Util.js";

export function HashIdentity_FromFunctions(hash, eq) {
    return {
        Equals(x, y) {
            return eq(x, y);
        },
        GetHashCode(x_1) {
            return hash(x_1);
        },
    };
}

export function HashIdentity_Structural() {
    return HashIdentity_FromFunctions((obj) => structuralHash(obj), (e1, e2) => equals(e1, e2));
}

export function HashIdentity_Reference() {
    return HashIdentity_FromFunctions((obj) => physicalHash(obj), (e1, e2) => (e1 === e2));
}

export function ComparisonIdentity_FromFunction(comparer) {
    return {
        Compare(x, y) {
            return comparer(x, y);
        },
    };
}

export function ComparisonIdentity_Structural() {
    return ComparisonIdentity_FromFunction((e1, e2) => compare(e1, e2));
}

