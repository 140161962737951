import { Union, Record } from "./.fable/fable-library.3.1.15/Types.js";
import { Alerts$reflection, ServerInfo$reflection } from "./Shared/Shared.js";
import { unit_type, equals, obj_type, union_type, record_type, class_type, string_type, list_type } from "./.fable/fable-library.3.1.15/Reflection.js";
import { fromString, Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "./.fable/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_message, Helper_fetch, Helper_withContentTypeJson, Helper_withProperties, FetchError } from "./.fable/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.1.15/Choice.js";
import { map, defaultArg } from "./.fable/fable-library.3.1.15/Option.js";
import { Types_RequestProperties } from "./.fable/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton as singleton_1, length, filter, cons, ofArray, empty } from "./.fable/fable-library.3.1.15/List.js";
import { keyValueList } from "./.fable/fable-library.3.1.15/MapUtil.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430, toString } from "./.fable/Thoth.Json.7.0.0/Encode.fs.js";
import { createObj, uncurry } from "./.fable/fable-library.3.1.15/Util.js";
import { FSharpMap__TryFind, empty as empty_1 } from "./.fable/fable-library.3.1.15/Map.js";
import { empty as empty_2, map as map_1, append, singleton, collect, delay, toList } from "./.fable/fable-library.3.1.15/Seq.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.57.0/Interop.fs.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.18.0/ElementBuilders.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.57.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./.fable/Feliz.Router.3.8.0/Router.fs.js";

export class Model extends Record {
    constructor(Servers, Alerts) {
        super();
        this.Servers = Servers;
        this.Alerts = Alerts;
    }
}

export function Model$reflection() {
    return record_type("Index.Model", [], Model, () => [["Servers", list_type(ServerInfo$reflection())], ["Alerts", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Alerts$reflection()])]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GotAlerts", "GotServers"];
    }
}

export function Msg$reflection() {
    return union_type("Index.Msg", [], Msg, () => [[["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Alerts$reflection()])]], [["Item", list_type(ServerInfo$reflection())]]]);
}

export function getAlerts(dispatch) {
    const decoder = Auto_generateBoxedDecoder_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Alerts$reflection()]), void 0, void 0);
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let data_1, caseStrategy_2, extra_2;
        return (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_3, headers_3;
            try {
                const properties_5 = Helper_withProperties(void 0, (properties_3 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(void 0, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(map((data_3) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_3))), properties_3), data_1), properties_3)));
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helper_fetch("/api/getAlerts", properties_5).then(((_arg1) => {
                    let response_1, decoder_4;
                    return (response_1 = _arg1, (decoder_4 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Alerts$reflection()]), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response_1.text().then(((_arg1_1) => {
                        let matchValue;
                        return Promise.resolve((equals(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, Alerts$reflection()]), unit_type) ? (new FSharpResult$2(0, void 0)) : (matchValue = fromString(uncurry(2, decoder_4), _arg1_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    }))))) : (Promise.resolve((new FSharpResult$2(1, new FetchError(2, response_1)))))).then(((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, new FetchError(3, arg)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve((new FSharpResult$2(1, new FetchError(0, exn)))))));
            }
        })()))).then(((_arg2_1) => {
            const result_1 = _arg2_1;
            let response_3;
            if (result_1.tag === 1) {
                throw (new Error(Helper_message(result_1.fields[0])));
            }
            else {
                response_3 = result_1.fields[0];
            }
            return Promise.resolve(response_3);
        }));
    })).then(((_arg1_2) => {
        dispatch(new Msg(0, _arg1_2));
        return Promise.resolve();
    })))));
    pr_1.then();
}

export function getServers(dispatch) {
    const decoder = Auto_generateBoxedDecoder_79988AEF(list_type(ServerInfo$reflection()), void 0, void 0);
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let data_1, caseStrategy_2, extra_2;
        return (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
            let properties_3, headers_3;
            try {
                const properties_5 = Helper_withProperties(void 0, (properties_3 = ofArray([new Types_RequestProperties(0, defaultArg(void 0, "GET")), (headers_3 = Helper_withContentTypeJson(data_1, defaultArg(void 0, empty())), new Types_RequestProperties(1, keyValueList(headers_3, 0)))]), defaultArg(map((data_3) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_3))), properties_3), data_1), properties_3)));
                const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helper_fetch("/api/getServers", properties_5).then(((_arg1) => {
                    let response_1, decoder_4;
                    return (response_1 = _arg1, (decoder_4 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(list_type(ServerInfo$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response_1.text().then(((_arg1_1) => {
                        let matchValue;
                        return Promise.resolve((equals(list_type(ServerInfo$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : (matchValue = fromString(uncurry(2, decoder_4), _arg1_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                    }))))) : (Promise.resolve((new FSharpResult$2(1, new FetchError(2, response_1)))))).then(((_arg2) => (Promise.resolve(_arg2)))))))));
                })))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, new FetchError(3, arg)))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve((new FSharpResult$2(1, new FetchError(0, exn)))))));
            }
        })()))).then(((_arg2_1) => {
            const result_1 = _arg2_1;
            let response_3;
            if (result_1.tag === 1) {
                throw (new Error(Helper_message(result_1.fields[0])));
            }
            else {
                response_3 = result_1.fields[0];
            }
            return Promise.resolve(response_3);
        }));
    })).then(((_arg1_2) => {
        dispatch(new Msg(1, _arg1_2));
        return Promise.resolve();
    })))));
    pr_1.then();
}

export function init() {
    return new Model(empty(), empty_1());
}

export function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(msg.fields[0], model.Alerts);
    }
    else {
        return new Model(model.Servers, msg.fields[0]);
    }
}

export function alertsBox(model, dispatch) {
    let elms_2;
    const elms_3 = singleton_1((elms_2 = toList(delay(() => collect((server) => {
        let elms_1;
        const alerts = FSharpMap__TryFind(model.Alerts, server.Name);
        return singleton((elms_1 = toList(delay(() => {
            let props_1;
            if (alerts == null) {
                return singleton(createElement("div", {}));
            }
            else {
                const unexpectedAlerts = filter((x) => (!(x.Annotations.Message.indexOf("meant to ensure") >= 0)), alerts.Data.Alerts);
                return (length(unexpectedAlerts) > 0) ? append(singleton((props_1 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([createElement("h4", {
                    children: server.Name,
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))), delay(() => {
                    let elms;
                    return singleton((elms = toList(delay(() => map_1((alert) => createElement("p", {
                        children: alert.Annotations.Message,
                    }), unexpectedAlerts))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })));
                })) : empty_2();
            }
        })), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    }, model.Servers))), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })));
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

export function dashboardBox(model, dispatch) {
    let elms_2, elms_1;
    const elms_3 = singleton_1((elms_2 = singleton_1((elms_1 = toList(delay(() => map_1((server) => {
        let children_2;
        const elms = ofArray([createElement("h4", {
            children: server.Name,
        }), (children_2 = toList(delay(() => map_1((dashboard) => {
            const children = singleton_1(createElement("a", {
                children: dashboard.Name,
                href: dashboard.URL,
            }));
            return createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }, server.Dashboards))), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]);
        return createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }, model.Servers))), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })));
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

export function view(model, dispatch) {
    let elms_1, elms, props_4;
    const props_8 = ofArray([["className", "is-fullheight"], ["className", "is-primary"], ["style", {
        backgroundSize: "cover",
        backgroundPosition: "no-repeat center center fixed",
    }], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = singleton_1((props_4 = ofArray([["className", "is-12"], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-centered"], ["children", "Alerts"]])))), alertsBox(model, dispatch), createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-centered"], ["children", "Dashboards"]])))), dashboardBox(model, dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "hero-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_8)));
}

export const app = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const patternInput_1 = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput_1[1];
    useReact_useEffect_Z101E1A95(() => {
        getAlerts(dispatch);
    }, []);
    useReact_useEffect_Z101E1A95(() => {
        getServers(dispatch);
    }, []);
    const children = toList(delay(() => append(singleton(RouterModule_router({
        onUrlChanged: patternInput[1],
    })), delay(() => singleton(view(patternInput_1[0], dispatch))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}, void 0, uncurry(2, void 0), void 0, "app", "/builds/serit/innovasjon/serit-dashboards/src/Client/Index.fs", 135);

